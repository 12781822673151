<template>
    <v-select v-model="selectedTypes" :items="$store.state.products.types" :loading="isLoading" :disabled="disabled" item-value="key" :clear-icon="'far fa-times-circle'" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedTypes)" :multiple="multiple" :label="label" :placeholder="placeholder" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip color="accent" label small dark close @click:close="selectedTypes.splice(data.index, 1)">
                <v-icon class="mr-2" small> {{ getProductTypeIcon(data.item.key) }}</v-icon>
                {{ data.item.label }}
            </v-chip>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    <v-chip color="accent" label small dark>
                        <v-icon class="mr-2" small> {{ getProductTypeIcon(item.key) }}</v-icon>
                        {{ item.label }}
                    </v-chip>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
import productsMixin from '../../mixins/products.js';

export default {
    name: 'ProductTypeSelect',

    mixins: [productsMixin],

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },

    data: () => ({
        selectedTypes: null,
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    created() {
        this.selectedTypes = this.value;
    }
};
</script>