<template>
    <v-menu max-height="600" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <v-select :value="selectedLocation.slice(0)" :items="selectedLocation.slice(0)" :label="label" :placeholder="placeholder" background-color="white" :menu-props="{ offsetY: true, top: true, disabled: true }" hide-details outlined multiple dense v-on="on">
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small label>
                        <span> {{ item }} </span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ selectedLocation.length - 1 }} autres)
                    </span>
                </template>
            </v-select>
        </template>

        <v-card>
            <v-text-field v-model="search" class="location-search mx-4 mt-0" placeholder="Recherche" hide-details clearable clear-icon="far fa-times-circle" />

            <v-divider />

            <v-treeview v-model="selectedLocation" @input="$emit('input', selectedLocation)" :search="search" open-on-click selectable hoverable :items="items" />
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'ProgramLocationSelect',

    props: {
        value: { required: true },
        places: { default: () => [] },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },

    data: () => ({
        selectedLocation: null,
        items: [],
        search: null
    }),

    watch: {
        places() {
            this.generateItems();
        }
    },

    methods: {
        generateItems() {
            const regions = [];

            for (const place of this.places) {
                let region = regions.find((r) => r.id === place.region);
                if (!region) {
                    region = { id: place.region, name: place.region, children: [] };
                    regions.push(region);
                }

                let county = region.children.find((c) => c.id === place.county);
                if (!county) {
                    county = { id: place.county, name: place.county, children: [] };
                    region.children.push(county);
                }

                let city = county.children.find((c) => c.id === place.city);
                if (!city) {
                    city = { id: place.city, name: place.city };
                    county.children.push(city);
                }
            }

            this.items = regions;
        }
    },

    created() {
        this.selectedLocation = this.value;
        if (Array.isArray(this.places) && this.places.length > 0) {
            this.generateItems();
        }
    }
};
</script>

<style lang="scss" scoped>
.location-search::v-deep {
    .v-input__slot:before,
    .v-input__slot:after {
        border: 0;
    }
}
</style>